import * as Yup from 'yup'
import { mergeAll, filter, reject, equals } from 'ramda'
import {
  ValidationPhaseEnum,
  ValidationTypeEnum,
  QuestionTypeEnum,
  QuestionGroup,
  Question
} from '../components/DynamicForm/types'

// JC: For the moment we will just make number validation for phone number until
// We're clear about what we want regarding country code
// import 'yup-phone'
/**
 * https://github.com/abhisekp/yup-phone/issues/313
 */
export const addCustomPhoneValidation = () => {
  // @ts-ignore
  Yup.addMethod(
    Yup.string,
    'validatePhone',
    function (errorMessage = "This phone number doesn't seem to look valid") {
      return this.test('test-phone', errorMessage, (value: any) => {
        if (!value) return false

        if (!isNaN(value as any)) {
          return `${value}`.length > 0
        }

        return true
      })
    }
  )
}

export const addCustomDateValidation = () => {
  const defaultYearValue = 2
  // @ts-ignore
  Yup.addMethod(
    Yup.string,
    'validateDate',
    function (
      errorMessage = 'Invalid Date',
      type = 'max',
      validationValue = defaultYearValue
    ) {
      return this.test('test-date', errorMessage, value => {
        try {
          const now = new Date()
          const dateToValidate = new Date(
            now.getFullYear() + validationValue * (type === 'max' ? 1 : -1),
            now.getMonth(),
            now.getDay()
          )
          if (value) {
            const valueDate = new Date(value)
            return type === 'max'
              ? valueDate <= dateToValidate
              : valueDate >= dateToValidate
          }
          return false
        } catch (error) {
          return false
        }
      })
    }
  )
}

const getSchemaEntity = (type: QuestionTypeEnum) => {
  const dict = {
    [QuestionTypeEnum.TextInput]: Yup.string(),
    [QuestionTypeEnum.Textarea]: Yup.string(),
    [QuestionTypeEnum.Integer]: Yup.number(),
    [QuestionTypeEnum.Rating]: Yup.number(),
    [QuestionTypeEnum.MultiSelect]: Yup.array().nullable(), // Keeping as an array but it could be nullable or an empty list
    [QuestionTypeEnum.Checkbox]: Yup.boolean().nullable(),
    [QuestionTypeEnum.DateInput]: Yup.string(), // Keeping string until it needs to be mixed
    [QuestionTypeEnum.Dropdown]: Yup.string().nullable(), // Keeping string until it needs to be mixed
    [QuestionTypeEnum.FileDropzone]: Yup.string().nullable(),
    default: null // Ensure are types are handled
  }
  return type in dict ? dict[type] : dict['default']
}

/**
 * Generate Schema for question from validation
 * errorMessage relates to internationalization, so passing the
 * errorMessage as a parameter to the validation will then send it to a formik
 * handler when an error occurs or can be handled custom on server
 */
const generateSchema = (
  question: Question,
  phase?: ValidationPhaseEnum,
  schema?: any,
  isInnovatorAlumni?: boolean
) => {
  addCustomPhoneValidation()
  addCustomDateValidation()

  try {
    let currentValidations
    let qType

    // To keep support of old form
    const validations = question.questionValidationMaps
    currentValidations = validations.filter(validation => {
      return (
        validation.phase === phase ||
        validation?.phase === ValidationPhaseEnum.All
      )
    })

    qType = question.type

    let schemaEntity = schema || getSchemaEntity(qType)

    /**
     * Yup passes NaN when we use null and adding nullable doesn't seem
     * to fix the issue. So we need to check for NaN and handle it. We
     * should revisit this later when we build integer input.
     */
    if (schemaEntity?.type === 'number') {
      schemaEntity = schemaEntity.transform(value =>
        isNaN(value) ? undefined : value
      )
    }

    currentValidations.forEach(v => {
      const type = v?.validation?.type

      const dict = {
        [ValidationTypeEnum.required]: schemaEntity
          .trim()
          .required(v?.errorMessage || 'Required'),
        [ValidationTypeEnum.notRequired]: schemaEntity.notRequired(),
        [ValidationTypeEnum.nullable]: schemaEntity.nullable(),
        ...(schemaEntity.type === 'boolean' || qType === 'dateInput'
          ? {}
          : {
              [ValidationTypeEnum.min]: schemaEntity.min(
                Number(
                  typeof v.value === 'string' && v.value?.length
                    ? JSON.parse(v.value)?.length
                    : v.value?.length
                ),
                v.errorMessage
              ),
              [ValidationTypeEnum.max]: schemaEntity.max(
                Number(
                  typeof v.value === 'string' && v.value?.length
                    ? JSON.parse(v.value)?.length
                    : v.value?.length
                ),
                v.errorMessage
              )
            }),
        [ValidationTypeEnum.matches]:
          schemaEntity.type === 'string'
            ? schemaEntity.matches(v.value?.regex, v.errorMessage)
            : schemaEntity,
        ...(schemaEntity.type === 'array' ||
        schemaEntity.type === 'number' ||
        schemaEntity.type === 'boolean'
          ? {}
          : {
              [ValidationTypeEnum.email]: schemaEntity
                .trim()
                .email(v?.errorMessage || 'Email Required'),
              [ValidationTypeEnum.phone]: schemaEntity
                .trim()
                .validatePhone(v?.errorMessage || 'Invalid phone number')
            }),
        ...(qType !== 'dateInput'
          ? {}
          : {
              [ValidationTypeEnum.max]: schemaEntity
                .trim()
                .validateDate(
                  v?.errorMessage || 'Invalid Date',
                  'max',
                  v.value?.value
                ),
              [ValidationTypeEnum.min]: schemaEntity
                .trim()
                .validateDate(
                  v?.errorMessage || 'Invalid Date',
                  'min',
                  v.value?.value
                )
            }),
        [ValidationTypeEnum.innovatorAlumni]: isInnovatorAlumni
          ? schemaEntity.trim().required(v?.errorMessage || 'Required')
          : schemaEntity.nullable(),

        default: schemaEntity
      }
      schemaEntity = type in dict ? dict[type] : dict['default']
    })

    return schemaEntity
  } catch (error) {
    console.info('Error during schema Generation', error)
  }
}

const generateDependentSchema = (
  question: Question,
  phase?: ValidationPhaseEnum
) => {
  try {
    const parents = filter(
      qd => qd?.question2Id === question.id,
      question?.questionDependencies
    )

    let schemaEntity: any = getSchemaEntity(question?.type)
    parents.forEach(qd => {
      schemaEntity = schemaEntity.when(`${qd.question1Id}`, {
        is: qd?.value?.entityAnswer,
        then: schema => generateSchema(question, phase, schema),
        otherwise: schema => schema.nullable().notRequired()
      })
    })

    return schemaEntity
  } catch (error) {
    console.info('Error during schema Generation', error)
  }
}

const generateGroupSchema = (
  questionGroup: QuestionGroup,
  questions: Question[],
  phase?: ValidationPhaseEnum,
  isInnovatorAlumni?: boolean
) => {
  try {
    let schemaObject = {}

    questionGroup?.questionGroupEntityMaps?.forEach(questionEntityMap => {
      const question = questions.find(q => q.id === questionEntityMap.entity.id)

      if (!question) return

      if (question?.questionDependencies?.length) {
        const isChild = filter(
          qd => qd?.question2Id === question.id,
          question?.questionDependencies
        ).length

        if (isChild) {
          schemaObject[questionEntityMap.entity.id] = generateDependentSchema(
            question,
            phase
          )
        } else {
          schemaObject[questionEntityMap.entity.id] = generateSchema(
            question,
            phase
          )
        }
      } else {
        schemaObject[questionEntityMap.entity.id] = generateSchema(
          question,
          phase,
          null,
          isInnovatorAlumni
        )
      }
    })

    return schemaObject
  } catch (error) {
    console.info('Error during schema Generation', error)
  }
}

const generateSchemaValidation = (
  questionGroups: QuestionGroup[],
  questions: Question[],
  phase?: ValidationPhaseEnum,
  isInnovatorAlumni?: boolean
) => {
  const schemas: any[] = []

  questionGroups.forEach(questionGroup => {
    const schema: any = generateGroupSchema(
      questionGroup,
      questions,
      phase,
      isInnovatorAlumni
    )

    if (schema !== undefined) {
      schemas.push(schema)
    }
  })

  const filtered = reject(equals(null), mergeAll(schemas))
  return Yup.object().shape(filtered)
}

export default generateSchemaValidation
