import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components/native'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
// Components
import CloudinaryImage from '../../../components/CloudinaryImage'
import TouchableIconProps from '../../../components/icon/TouchableIcon'
// Types
import { FileDropzoneFile } from '../../../components/common/FileDropzone'
import { IMAGE_MAX_FILE_SIZE_MB, acceptSignatures } from './types'

// Constants
const IMAGE_SIZE = 160

// Styled components

const IconContainer = styled.View`
  z-index: 1;
  top: 10px;
  right: 10px;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  ${({ theme: { sizes } }) => `gap: ${sizes[1]}px;`}
`

const TouchableIcon = styled(TouchableIconProps).attrs(
  ({ theme: { fontSizes, colors }, name = 'trash' }) => ({
    name,
    width: fontSizes[4],
    height: fontSizes[4],
    color: colors.tints.grays.g400,
    hitSlop: {
      top: 5,
      bottom: 5,
      left: 5,
      right: 5
    }
  })
)`
  align-items: center;
  justify-content: center;
  background-color: white;
  ${({ theme: { sizes, radii, colors } }) => `
    padding: ${sizes[1]}px;
    border-radius: ${radii[6]}px;
    border: 1px solid ${colors.border};
  `}
`

const UploadIcon = styled(TouchableIconProps).attrs(
  ({ theme: { fontSizes, colors } }) => ({
    name: 'imageUpload',
    width: fontSizes[8],
    height: fontSizes[8],
    color: colors.primaryPalette.black
  })
)``

const transformsFromCropping = cropping => {
  const unitRate = cropping.unit === 'px' ? 1 : 100
  return {
    transformation: [
      {
        height: cropping.height / unitRate,
        width: cropping.width / unitRate,
        x: cropping.x / unitRate,
        y: cropping.y / unitRate,
        crop: 'crop'
      }
    ]
  }
}

const StyledCloudinaryImage = styled(CloudinaryImage).attrs(({ cropping }) => {
  return {
    resizeMode: 'contain',
    transforms:
      cropping && (cropping?.height < 100 || cropping?.width < 100)
        ? transformsFromCropping(cropping)
        : undefined
  }
})`
  height: 100%;
  width: 100%;
`
const UploadLabel = styled.Text`
  text-align: center;
  ${({ subtitle, theme: { fontSizes, colors } }) => `
    font-size: ${fontSizes[subtitle ? 2 : 3]}px;
    color: ${colors.tints.grays.g300};
  `}
`
const UploadContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ImageContainer = styled.TouchableOpacity`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.tints.grays.g100};
  ${({ isSmallScreen, size, theme: { radii, space } }) => {
    const currentSize = size ?? IMAGE_SIZE
    return `
      padding: ${space[3]}px;
      border-radius: ${radii[3]}px;
      min-height: ${currentSize}px;
      min-width: ${currentSize * (isSmallScreen ? 1.5 : 2.25)}px;
    `
  }}
`
const StyledLoadingIndicator = styled.ActivityIndicator.attrs({
  size: 'large',
  color: 'black'
})`
  position: absolute;
`

interface CompanyLogoProps {
  size?: number
  canRemove?: boolean
  canUpload?: boolean
  loading?: boolean
  logo?: {
    id?: string
    cloudinaryId?: string
    logoCropping?: string
  }
  accept?: any
  onEdit?: () => void
  onLoad?: (file: FileDropzoneFile) => void
  onRemove?: () => void
  onDropRejected?: (fileRejections: any) => void
}

const CompanyLogo = React.memo(
  ({
    size,
    logo,
    canRemove,
    canUpload,
    loading,
    accept = acceptSignatures,
    onLoad,
    onRemove,
    onDropRejected
  }: CompanyLogoProps) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const onDrop = useCallback(async acceptedFiles => {
      const file = acceptedFiles[0]
      await onLoad?.(file)
    }, [])

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      multiple: false,
      accept,
      disabled: !canUpload,
      onDropRejected
    })

    const inputProps: any = getInputProps()
    const rootProps = getRootProps({
      onClick: e => e.stopPropagation()
    })

    const cropping = logo?.logoCropping ? JSON.parse(logo.logoCropping) : null
    const showImage = logo && logo?.cloudinaryId
    const canReplace = showImage && !loading

    const buttonProps = {
      disabled: loading || showImage || !canUpload,
      onPress: !loading && !showImage ? open : undefined
    }

    return (
      <ImageContainer
        {...rootProps}
        {...buttonProps}
        size={size}
        isSmallScreen={isSmallScreen}
      >
        <input {...inputProps} tabIndex={-1} />
        {canRemove || canReplace ? (
          <IconContainer>
            {canReplace ? <TouchableIcon name="pencil" onPress={open} /> : null}
            {canRemove ? <TouchableIcon onPress={onRemove} /> : null}
          </IconContainer>
        ) : null}
        {loading ? (
          <StyledLoadingIndicator />
        ) : showImage ? (
          <StyledCloudinaryImage
            publicId={logo?.cloudinaryId}
            cropping={cropping}
          />
        ) : (
          <UploadContainer>
            <UploadIcon {...buttonProps} />
            <UploadLabel>
              {t('directoryProfile:companyLogoUploader:upload')}
            </UploadLabel>
            <UploadLabel subtitle>
              {t('directoryProfile:companyLogoUploader:maxImageFileSize', {
                size: IMAGE_MAX_FILE_SIZE_MB
              })}
            </UploadLabel>
          </UploadContainer>
        )}
      </ImageContainer>
    )
  }
)

export default CompanyLogo
