import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { CallForSubmission } from '../../../types'

export const callForSubmissionQuery = gql`
  query callForSubmission(
    $id: String!
    $withSubmissionReviews: Boolean!
    $withSubmissionReviewsAverages: Boolean!
    $withInnovationSubmissions: Boolean!
    $filterIncomplete: Boolean
  ) {
    callForSubmission(id: $id) {
      id
      name
      status
      academyElegible
      shortlistLimit
      favoritesLimit
      canEditSubmissions
      stepDateRanges {
        id
        curationStep
        openAt
        closeAt
      }
      currentStep {
        name
        status
        openAt
        closeAt
      }
      advisorMaxFav
      advisorsFavCount {
        id
        favCount
        favAnswers {
          id
          formSubmissionId
        }
      }
      advisors
      advisorsUsers {
        id
        cfsSubmissionReviews(cfsId: $id, filterIncomplete: $filterIncomplete) {
          submissionId
        }
        submissionsCount
        roles
        person {
          emailAddresses {
            email
          }
          firstName
          lastName
          employerName
        }
      }
      reviewFormId
      reviewFormQuestions {
        id
        questionText
        type
        configData
        optionsValues
        style
        systemLabel
        weight
      }
      cutFormId
      delegations {
        id
        name
        groups {
          id
          name
          shortName
        }
      }
      marketSegments {
        id
        name
      }
      innovationSubmissions @include(if: $withInnovationSubmissions) {
        id
        step
        status
        updatedAt
        hasBeenFastPassed
        hasBeenSubmitted
        callForSubmissionId
        cohorts {
          group {
            id
            name
            shortName
          }
          deletedById
          isConfirmed
        }
        cohortsHistory {
          id
          name
          shortName
        }
        company {
          id
          name
        }
        submissionInfo {
          name
          category
          categoryLabel
          otherCategoryLabel
        }
        submissionReviews @include(if: $withSubmissionReviews) {
          avgScore
          userId
        }
        advisorsReviews {
          advisorId
          answers {
            id
            value
            questionId
          }
        }
        submissionAverage @include(if: $withSubmissionReviewsAverages) {
          avgScore
          stdDev
          totalFav
          totalAcademy
          wass
        }
        passOrCutText
      }
    }
  }
`

const useCallForSubmissionQuery = (
  id,
  withSubmissionReviews = false,
  withSubmissionReviewsAverages = false,
  withInnovationSubmissions = false,
  queryOptions = {},
  filterIncomplete = false
) => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(callForSubmissionQuery, {
    variables: {
      id,
      withSubmissionReviews,
      withSubmissionReviewsAverages,
      withInnovationSubmissions,
      filterIncomplete
    },
    skip: !id,
    fetchPolicy: 'cache-first',
    errorMessage: t('error:submissions:getCallsForSubmission'),
    ...queryOptions
  })

  return {
    callForSubmission: data?.callForSubmission as CallForSubmission,
    refetch,
    loading
  }
}

export default useCallForSubmissionQuery
