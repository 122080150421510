import React from 'react'
import styled from 'styled-components/native'

import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import { Body } from './LoginInfo'
import { H3 } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import { P } from '../../HomeScreen/content'

const CalendarRowContainer = styled.View`
  display: flex;
  flex-direction: row;
  ${({ isHomeScreen, theme: { sizes, radii, colors } }) => `
    height: ${sizes[4] - sizes[1]}px;
    border-radius: ${radii[3]}px;
    margin-top: ${sizes[1]}px;
    margin-bottom: ${sizes[1]}px;
    background: ${isHomeScreen ? colors.dullBlue : colors.darkBackground};
  `}
`

const DateContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  ${({ isHomeScreen, theme: { sizes, radii, colors } }) => `
    height: ${sizes[4] - sizes[1]}px;
    border-top-left-radius: ${radii[3]}px;
    border-bottom-left-radius: ${radii[3]}px;;
    padding-horizontal: ${sizes[1]}px;
    background: ${
      isHomeScreen ? colors.lightBlueBackground : colors.blueBackground
    };
  `}
`

const DelegationContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 67%;
  ${({ theme: { sizes } }) => `
    padding-horizontal: ${sizes[3] + sizes[1]}px;
  `}
`

const ShortNameContainer = styled.View`
  display: flex;
  flex-direction: row;
  ${({ theme: { sizes } }) => `
    width: ${sizes[4] - 2}px;
  `}
`

const Date = styled.Text`
  ${({ theme: { fontWeights, fontSizes, colors } }) => `
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes[3]}px;
    color:${colors.darkBackground};
  `}
`

const Delegation = styled.Text`
  ${({ isHomeScreen, theme: { fontWeights, fontSizes, colors } }) => `
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes[3]}px;
  color: ${isHomeScreen ? colors.darkBackground : colors.buttonText};
  `}
`

interface CalendarRowProps {
  date: string
  delegation: string
  delShortName: string
  isHomeScreen?: boolean
}

const CalendarRow = ({
  date,
  delegation,
  delShortName,
  isHomeScreen
}: CalendarRowProps) => {
  return (
    <CalendarRowContainer isHomeScreen={isHomeScreen}>
      <DateContainer isHomeScreen={isHomeScreen}>
        <Date>{date}</Date>
      </DateContainer>
      <DelegationContainer>
        <Delegation isHomeScreen={isHomeScreen}>{delegation}</Delegation>
        <ShortNameContainer>
          <Delegation isHomeScreen={isHomeScreen}>{delShortName}</Delegation>
        </ShortNameContainer>
      </DelegationContainer>
    </CalendarRowContainer>
  )
}

const SubmissionCalendar = ({ isHomeScreen, marginTop }) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const isWindowCalendarEnabled = useFeatureFlag(
    'innovationDisplayWindowCalendar'
  )
  const isCalendarEnabledToHomeScreen = useFeatureFlag(
    'innovationWindowCalendarHomeScreen'
  )

  return (
    <Flex marginTop={marginTop} alignSelf={'flex-start'} width="100%">
      {!isHomeScreen ? (
        <H3
          styles={{ marginBottom: 16, fontWeight: '500' }}
          h3Style={{ fontSize: isSmallScreen ? '18px' : '20px' }}
        >
          {t('auth:login:submissionCalendar')}
        </H3>
      ) : null}
      {(isWindowCalendarEnabled && !isHomeScreen) ||
      (isCalendarEnabledToHomeScreen && isHomeScreen) ? (
        <>
          <CalendarRow
            date={'OCT 14 - JAN 06'}
            delegation={'HR & DEI'}
            delShortName={'IDIR'}
            isHomeScreen={isHomeScreen}
          />
          <CalendarRow
            date={'OCT 14 - JAN 13'}
            delegation={'Legal Tech'}
            delShortName={'LTIR'}
            isHomeScreen={isHomeScreen}
          />
          <CalendarRow
            date={'NOV 04 - FEB 10'}
            delegation={'Marketing Tech'}
            delShortName={'MTIR'}
            isHomeScreen={isHomeScreen}
          />
          <CalendarRow
            date={'NOV 05 - NOV 20'}
            delegation={'Employer Health'}
            delShortName={'EHIR'}
            isHomeScreen={isHomeScreen}
          />
          <CalendarRow
            date={'DEC 02 - MAR 03'}
            delegation={'Procurement'}
            delShortName={'PIR'}
            isHomeScreen={isHomeScreen}
          />
        </>
      ) : !isHomeScreen ? (
        <Body>{t('auth:login:submissionCalendarDisabled')}</Body>
      ) : (
        <P>{t('homeScreen:noSubmissionwindows')}</P>
      )}
    </Flex>
  )
}

export default SubmissionCalendar
