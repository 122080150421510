import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native'

type JSONValue = string | number | boolean | JSONObject | JSONArray

export interface JSONObject {
  [x: string]: JSONValue
}

export interface GenericStyles {
  [key: string]:
    | StyleProp<ViewStyle>
    | StyleProp<TextStyle>
    | StyleProp<ImageStyle>
    | string
    | number
    | boolean
}

export interface JSONStyles extends GenericStyles {
  renderCounter?: boolean
  textareaLineHeight?: number
  containerStyles?: StyleProp<ViewStyle>
  errorContainerStyles?: StyleProp<ViewStyle>
  labelStyles?: StyleProp<TextStyle>
  buttonStyles?: StyleProp<ViewStyle>
  imageStyles?: StyleProp<ImageStyle>
  styles?: StyleProp<ViewStyle> | StyleProp<TextStyle> | StyleProp<ImageStyle>
  checkedIconName?: string
  questionWeight?: number
}

export enum QuestionTypeEnum {
  TextInput = 'textInput',
  Textarea = 'textarea',
  Integer = 'integer',
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  FileDropzone = 'fileDropzone',
  Rating = 'rating',
  DateInput = 'dateInput',
  MultiSelect = 'multiSelect'
}

export enum QuestionSubTypeEnum {
  DbLookup = 'dbLookup',
  OptionValues = 'optionValues',
  CheckboxGroup = 'checkboxGroup'
}

export enum QuestionGroupTypeEnum {
  Form = 'form',
  Tab = 'tab',
  Block = 'block',
  DynamicBlock = 'blockDynamic'
}

export enum ValidationTypeEnum {
  required = 'required',
  notRequired = 'notRequired',
  nullable = 'nullable',
  min = 'min',
  max = 'max',
  matches = 'matches',
  email = 'email',
  phone = 'phone',
  innovatorAlumni = 'innovatorAlumni'
}

export enum ValidationPhaseEnum {
  All = 'all',
  Save = 'save',
  Submit = 'submit',
  Review = 'review',
  Publish = 'publish'
}

export interface Validation {
  id: string
  type: ValidationTypeEnum
}

export interface Answer {
  id?: string
  value: any
  answerEntityId?: string
  answerEntityType?: string
  formSubmissionId?: string
  questionId: string
  question?: Question
}

export interface QuestionValidationMap {
  id: string
  errorMessage?: string
  phase?: ValidationPhaseEnum
  value: any
  validation: Validation
}

export interface Question {
  id: string
  parentId?: string
  parent?: Question
  questionText: string
  type: QuestionTypeEnum
  subType?: string
  style: JSONStyles
  configData?: JSONObject
  weight?: number
  dataEntityId?: string
  dataEntityType: string
  optionsEntity?: string
  optionsValues?: any
  answer: Answer
  questionValidationMaps: QuestionValidationMap[]
  dynamicAnswers: Answer[]
  __typename: string
}

export interface QuestionGroupEntityMap {
  id: string
  parentId?: string
  parent?: QuestionGroupEntityMap
  questionGroupId: string
  entityType: EntityTypeEnum
  entityId: string
  entity: Entities
  __typename: string
}

export interface StepsRenderOptions {
  name?: string
  shouldHide?: boolean
}

export interface InnovationFormTypeRenderOptions {
  formType?: string
  shouldHide?: boolean
}

export interface PlatformRenderOptions {
  steps?: StepsRenderOptions[]
  innovationFormTypes?: InnovationFormTypeRenderOptions[]
}

export interface RenderOptions {
  innovation?: PlatformRenderOptions
}

export interface QuestionGroupConfigData {
  title?: string
  blockLabel?: string
  capitalized?: boolean
  titleStyle?: any
  description?: string
  buttonLabel?: string
  renderOptions?: RenderOptions
}

export interface QuestionGroup {
  id: string
  parentId?: string
  parent?: QuestionGroup
  dynamic?: boolean
  type: QuestionGroupTypeEnum
  style: JSONStyles
  questionGroupEntityMaps: QuestionGroupEntityMap[]
  configData: QuestionGroupConfigData
  __typename: string
}

export type Entities = Question | QuestionGroup

export enum EntityTypeEnum {
  Question = 'question',
  QuestionGroup = 'question_group'
}

export interface FormData {
  id: string
  name: string
  questions: Question[]
  questionGroups: QuestionGroup[]
  formGroup: QuestionGroup
}

export interface QuestionCondition {
  isDisabled: () => boolean
  customMsg: string
  onChange?: (newVal) => void
}

export interface FormQuestionConditions {
  [key: string]: QuestionCondition
}

export enum AnswerEntityTypeEnum {
  InnovationSubmission = 'InnovationSubmission',
  InnovationProduct = 'InnovationProduct',
  InnovationReview = 'InnovationReview',
  InnovationCutFeedback = 'InnovationCutFeedback'
}

// TODO to remove
export enum ProductTabsEnum {
  General = 'general',
  PitchDeck = 'pitchdeck_innovation',
  Certification = 'certification_innovation',
  Customers = 'customers_innovation',
  Competitors = 'competitors_innovation'
}

// SubStates access allowed
export enum ALLOWED_LOGIN_ROUTES {
  REGISTER = 'REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD'
}
